<template>
  <Content>
    <template #contentTitle>{{ $t('menu.consignment_inbound_confirm') }}</template>
    <template #contentBody>
      <a-spin :spinning="loading">
        <StepsRow :current="1" />
        <a-row :gutter="[8, 8]">
          <a-col :span="24">{{ $t('warehouse.in_warehouse_plan_no') }}: {{ pageState.planNo || '-' }}</a-col>
          <a-col :span="24">
            <span>{{ $t('warehouse.in_plan_name') }}: </span>
            <template v-if="isEditName">
              <a-input ref="planNameRef" v-model:value="formState.planName" style="width: 250px;" size="small" @blur="handleConfirmEdit"></a-input>
              <a-button class="ml-2" type="primary" size="small" @click="handleConfirmEdit">{{ $t('common.confirm') }}</a-button>
            </template>
            <template v-else>
              <span>{{ formState.planName || pageState.planName }}</span>
              <a-button v-if="pageState.inWarehouseType === inWarehouseTypeEnum.directEntry" class="ml-2" type="primary" size="small" ghost @click="handleEditName">{{ $t('account.modify') }}</a-button>
            </template>
          </a-col>
          <a-col :span="24" :lg="12" :xl="8">
            {{ $t('warehouse.in_target_warehouse') }}: 
            <span v-if="pageState.to">{{ pageState.to.no }}({{ pageState.to.name }})</span>
            <span v-else>{{ '-' }}</span>
          </a-col>
          <a-col :span="24" :lg="12" :xl="16">
            {{ $t('warehouse.warehouse_address') }}: 
            <template v-if="pageState.to">
              <span>{{ getFullAddress(pageState.to) }}</span>
              <span class="ml-1">({{ $t("logistics.contacts") }}: {{ getLinkman(pageState.to) }})</span>
            </template>
            <span v-else>{{ '-' }}</span>
          </a-col>
          <a-col :span="24" :lg="12" :xl="8">
            {{ $t('warehouse.originate') }}: 
            <span v-if="pageState.from">{{ pageState.from.name }}</span>
            <span v-else>{{ '-' }}</span>
          </a-col>
          <a-col :span="24" :lg="12" :xl="16">
            {{ $t('warehouse.from_product_address') }}: 
            <template v-if="pageState.from">
              <span>{{ getFullAddress(pageState.from) }}</span>
              <span class="ml-1" v-if="pageState.from.isWarehouse">({{ $t("logistics.contacts") }}: {{ getLinkman(pageState.from) }})</span>
            </template>
            <span v-else>{{ '-' }}</span>
          </a-col>
        </a-row>
        <a-table
          style="overflow-x: auto;"
          class="mt-3"
          :columns="columns"
          :data-source="boxList"
          size="small"
          :expandIconAsCell="false"
          :expandIconColumnIndex="2"
          :pagination="pagination"
          :row-key="(record) => record.detailId"
          @change="(page) => pagination.current = page.current"
        >
          <template #serialNumber="{ index }">
            {{ ((pagination.current - 1) * pagination.pageSize) + (index + 1) }}
          </template>
          <template #boxNoTitle>
            <div>{{ $t('warehouse.box_no') }}</div>
            <div>{{ $t('common.user_defined_box_no') }}</div>
          </template>
          <template #boxNo="{ record }">
            <div>
              {{ record.box.boxNo || '-' }}
              <CTagMixedOrSingle :productKindCount="record.box.container.productKindCount * 1"></CTagMixedOrSingle>
            </div>
            <div>
              <UserDefinedBoxNo :boxId="record.box.whBoxId" :userDefinedBoxNo="record.box.selfBoxNo"></UserDefinedBoxNo>
            </div>
          </template>
          <template #expandIcon="{ expanded, record, onExpand }">
            <CHaveProductInformation :disabled="!record.box.container?.hasRelationProduct" :open="expanded" @click="e => onExpand(record, e)" />
          </template>
          <template #encasementSize="{ record }">
            <span v-if="record.box.container">{{ record.box.container.containerLength }}x{{ record.box.container.containerWidth }}x{{ record.box.container.containerHeight }}cm</span>
            <span v-else>-</span>
          </template>
          <template #encasementWeight="{ record }">
            <span v-if="record.box.container">{{ gToKg(record.box.container.containerWeight) }}kg</span>
            <span v-else>-</span>
          </template>
          <template #encasementCount="{ record }">
            {{ record.box.container ? record.box.container.containerCount : '-' }} Unit
          </template>
          <template #labelDisposeTitle>
            <a-select
              v-model:value="allLabelDispose"
              style="width: 200px;"
              :placeholder="$t('warehouse.label_dispose')"
              allow-clear
              @change="handleSelectLabelDispose"
            >
              <a-select-option v-for="(item, index) in labelDisposeEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('labelDispose', item))">{{ $t($enumLangkey('labelDispose', item)) }}</a-select-option>
            </a-select>
          </template>
          <template #labelDispose="{ record }">
            <a-select
              v-model:value="record.productLabelProcessing"
              style="width: 200px;"
              :placeholder="$t('warehouse.label_dispose')"
              allow-clear
              @change="handleSelectLabelDisposeRow(record)"
            >
              <a-select-option v-for="(item, index) in labelDisposeEnum"
              :key="index"
              :value="item"
              :title="$t($enumLangkey('labelDispose', item))">{{ $t($enumLangkey('labelDispose', item)) }}</a-select-option>
            </a-select>
          </template>
          <template #operationTitle>
            <a-button type="primary" ghost :loading="printLoadingId === 'allBox'" :disabled="!!printLoadingId" @click="handleBoxPrint()">{{ $t('common.bulk_print_box_label') }}</a-button>
          </template>
          <template #operation="{ record }">
            <a-button type="primary" ghost :loading="printLoadingId === record.detailId" :disabled="!!printLoadingId" @click="handleBoxPrint(record.detailId)">{{ $t('common.print_box_label') }}</a-button>
          </template>
          <template #footer>
            <a-row :gutter="16" type="flex" justify="space-around">
              <a-col>{{ $t('common.total') }}: {{ pageState.total ? pageState.total.plan.totalContainerBoxCount : '-' }}{{ $t('warehouse.box') }}</a-col>
              <a-col>{{ pageState.total ? cmCubicToM(pageState.total.plan.totalVolume) : '-' }}m<sup>3</sup></a-col>
              <a-col>{{ pageState.total ? gToKg(pageState.total.plan.totalWeight) : '-' }}kg</a-col>
              <a-col>{{ pageState.total ? pageState.total.plan.totalWarehouseCount : '-' }}Unit</a-col>
            </a-row>
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.box.items"
              :pagination="false"
              :row-key="(record, index) => index"
              size="small"
            >
              <template #productInfo="{ record }">
                {{ record.id }}
                <a-row :gutter="8" type="flex" align="middle">
                  <a-col>
                    <div class="table-list-img-common">
                      <c-image
                        :src="record.item.productImgUrl"
                        :thumbWidth="600"
                        :thumbHeight="600"
                      />
                    </div>
                  </a-col>
                  <a-col flex="1">
                    <div>{{ record.item.productName }}</div>
                    <div>{{ record.item.productNo }}</div>
                  </a-col>
                </a-row>
              </template>
              <template #encasementCount="{ record }">
                {{ record.item.containerCount }} Unit
              </template>
              <template #operation="{ record }">
                <a-button type="primary" :loading="printProductLoading" ghost @click="handleProductLabelPrint(record.item)">{{ $t('warehouse.print_product_labels') }}</a-button>
              </template>
            </a-table>
          </template>
        </a-table>
        <a-row v-if="!pageState.hasRelationTrans" class="mt-2" type="flex" justify="end">
          <a-col>
            <a-checkbox v-model:checked="createCwsTransPlan">
              <a-tooltip :title="$t('warehouse.transport_planning_requires_complete_product_information')"><QuestionCircleOutlined /></a-tooltip>
              {{ $t('warehouse.create_transport_plan_in_cws') }}
            </a-checkbox>
          </a-col>
        </a-row>
        <a-row class="mt-5" :gutter="32" type="flex" justify="end">
          <a-col><a-button v-if="pageState.isShowPrevButton" @click="handleBack">{{ $t('common.back_step') }}</a-button></a-col>
          <a-col><a-button type="primary" :loading="confirmLoading" @click="handleConfirm">{{ $t('common.confirm_in_plan') }}</a-button></a-col>
        </a-row>
      </a-spin>
    </template>
  </Content>
</template>

<script>
import { defineComponent, nextTick, onMounted, reactive, ref, toRefs } from 'vue';
import { Button, Checkbox, Col, Input, Row, Select, Spin, Table, Tooltip, message } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CImage from '@/views/components/CImage.vue';
import CHaveProductInformation from '@/views/components/CHaveProductInformation.vue';
import CTagMixedOrSingle from '@/views/components/CTagMixedOrSingle.vue';
import UserDefinedBoxNo from '@/views/components/UserDefinedBoxNo.vue';
import StepsRow from './components/StepsRow.vue';
import { useI18n } from "vue-i18n/index";
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { refInputElemFocus } from '@/utils/domoperate.js';
import localPrint from "@/utils/localPrint";
import { gToKg, cmCubicToM, setFormStateValue, getAddressByLanguageV2, getLinkman } from '@/utils/general.js';
import { useTab } from "@/hooks/tabs/index";
import {
  getDelegate,
  updateInWarehousePlanName,
  updateConfirmPlan,
  getInWarehousePlanBoxPrint,
  getInWarehpusePlanBoxBatchPrint,
} from '@/api/modules/consignment/inbound.js';
import {
  labelDispose as labelDisposeEnum,
  warehouseType as warehouseTypeEnum,
  inWarehouseType as inWarehouseTypeEnum,
} from '@/enum/enum.json';

export default defineComponent({
  name: 'consignment_inbound_confirm',
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AButton: Button,
    ATable: Table,
    ASelect: Select,
    ASelectOption: Select.Option,
    ACheckbox: Checkbox,
    ATooltip: Tooltip,
    Content,
    CImage,
    CHaveProductInformation,
    CTagMixedOrSingle,
    UserDefinedBoxNo,
    StepsRow,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();
    const { getters } = useStore();

    const planNameRef = ref(null);

    const state = reactive({
      loading: false,
      confirmLoading: false,
      isEditName: false,
      pageState: {
        planNo: null,
        planName: null,
        inWarehouseType: null,
        from: null,
        to: null,
        // 是否已经关联运输
        hasRelationTrans: false,
        // 是否显示上一步按钮
        isShowPrevButton: false,
        // 是否可以取消
        isCanCancel: false,
        // 仓库类型
        warehouseType: null,
        // 总计
        total: null,
      },
      formState: {
        planId: null,
        planName: '',
      },
      // 批量贴标
      allLabelDispose: null,
      // 箱列表
      boxList: [],
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        hideOnSinglePage: true,
        showSizeChanger: false,
        size: 'small',
        position: 'both',
      },
      // 是否需要创建计划
      createCwsTransPlan: false,
      // 打印loading Id
      printLoadingId: null,
      // 打印产品标
      printProductLoading: false,
    });

    const columns = [
      {
        width: 60,
        title: () => vueI18n.t("warehouse.serial_number"),
        slots: {
          customRender: "serialNumber"
        }
      },
      {
        width: 200,
        slots: {
          title: "boxNoTitle",
          customRender: "boxNo",
        }
      },
      { width: 50, },
      {
        width: 200,
        title: () => vueI18n.t("warehouse.encasement_size"),
        slots: {
          customRender: "encasementSize"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("warehouse.encasement_weight"),
        slots: {
          customRender: "encasementWeight"
        }
      },
      {
        width: 120,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "encasementCount"
        }
      },
      {
        width: 250,
        slots: {
          title: "labelDisposeTitle",
          customRender: "labelDispose"
        }
      },
      {
        width: 150,
        slots: {
          title: "operationTitle",
          customRender: "operation"
        }
      },
    ];

    const innerColumns = [
      { width: 60, },
      {
        width: 450,
        title: () => vueI18n.t("warehouse.product_info"),
        slots: {
          customRender: "productInfo"
        }
      },
      {
        title: 'SESKU',
        dataIndex: "item[seSku]",
        width: 150,
      },
      {
        width: 150,
        title: () => vueI18n.t("logistics.count"),
        slots: {
          customRender: "encasementCount"
        }
      },
      {
        slots: {
          customRender: "operation"
        }
      },
    ];

    const getFullAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    }

    // 打印箱标
    const handleBoxPrint = async (detailId) => {
      try {
        const data = { id: state.formState.planId }
        let printUrl = '';
        if (detailId) {
          Object.assign(data, { detailId });
          state.printLoadingId = detailId;
          printUrl = getInWarehousePlanBoxPrint(data);
        } else {
          state.printLoadingId = 'allBox';
          printUrl = getInWarehpusePlanBoxBatchPrint(data);
        }
        await localPrint(printUrl);
      } catch (error) {
      } finally {
        state.printLoadingId = null;
      }
    }

    // 打印产品标
    const handleProductLabelPrint = async (item) => {
      try {
        state.printProductLoading = true;
        await localPrint(item.productLabelUrl);
      } catch (error) {
      } finally {
        state.printProductLoading = false;
      }
    }

    const handleConfirmEdit = () => {
      let result = false;
      if (!state.formState.planName) {
        state.formState.planName = state.pageState.planName;
      }
      let reg = /"^$|^[0-9A-Za-z\-]{1,64}$/
      if (!reg.test(state.formState.planName)) {
        message.warning(vueI18n.t('common.the_input_length_is_too_long'));
        result = true;
      }
      state.isEditName = result;
    }

    const handleEditName = () => {
      state.isEditName = true;
      if (!state.formState.planName) {
        state.formState.planName = state.pageState.planName;
      }
      nextTick(() => {
        refInputElemFocus(planNameRef.value);
      });
    }

    const handleSelectLabelDispose = () => {
      state.boxList.forEach(item => {
        item.productLabelProcessing = state.allLabelDispose;
      });
    }

    const handleSelectLabelDisposeRow = (record) => {
      let list = state.boxList.filter(item => item.productLabelProcessing === record.productLabelProcessing);
      if (list.length === state.boxList.length) {
        state.allLabelDispose = record.productLabelProcessing;
      } else {
        state.allLabelDispose = null;
      }
    }

    const handleBack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push('/consignment/inbound/create/' + state.formState.planId);
    }

    const validateBoxData = () => {
      let result = true;
      let items = state.boxList.map(item => {
        if (!item.productLabelProcessing) {
          result = false;
        }
        return {
          detailId: item.detailId,
          productLabelProcessing: item.productLabelProcessing,
        }
      });
      if (!result) {
        message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.label_dispose')]));
      }
      return result ? items : result;
    }

    const handleConfirm = async () => {
      try {
        let items = validateBoxData();
        if (!items) {
          return;
        }
        state.confirmLoading = true;
        if (state.formState.planName && state.formState.planName !== state.pageState.planName) {
          // 先修改计划名称
          const planNameData = Object.assign({}, state.formState, {
            warehouseType: warehouseTypeEnum.consignmentWarehouse,
          })
          await updateInWarehousePlanName(planNameData);
        }
        const data = {
          warehouseType: warehouseTypeEnum.consignmentWarehouse,
          createCwsTransPlan: state.createCwsTransPlan,
          planId: state.formState.planId,
          items
        }
        await updateConfirmPlan(data);
        message.success(vueI18n.t('common.succeed'));
        // 跳转页面
        delVisitedRoute(router.currentRoute.value);
        router.push('/consignment/inbound/shipping/' + state.formState.planId);
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const getFirstStepDetail = () => {
      state.loading = true;
      getDelegate({ planId: state.formState.planId }).then(({ result }) => {
        setFormStateValue(state.pageState, result);
        state.boxList = result.items;
      }).catch(() => {}).finally(() => {
        state.loading = false;
      });
    }

    onMounted(() => {
      if ('id' in route.params) {
        let id = route.params.id;
        state.formState.planId = id;
        getFirstStepDetail();
      }
    });

    return {
      ...toRefs(state),
      gToKg,
      cmCubicToM,
      getLinkman,
      labelDisposeEnum,
      inWarehouseTypeEnum,
      planNameRef,
      columns,
      innerColumns,
      getFullAddress,
      handleBoxPrint,
      handleProductLabelPrint,
      handleConfirmEdit,
      handleEditName,
      handleSelectLabelDispose,
      handleSelectLabelDisposeRow,
      handleBack,
      handleConfirm,
    }
  }
})
</script>

<style scoped>

</style>